import { useQuery } from '@tanstack/react-query'
import { useOrg } from '../contexts'
import { FileUpload } from '../types/orgTypes'

export function useUploads() {
  const {
    orgId: [currentOrgId],
    orgFetch,
  } = useOrg()

  return useQuery({
    queryKey: ['uploads', currentOrgId],
    queryFn: getData,
  })

  async function getData() {
    const res = await orgFetch<FileUpload[]>('/api/org/upload/all')
    const data = await res.json()
    if (res.ok) {
      return data
    } else {
      throw new Error('something went wrong')
    }
  }
}
