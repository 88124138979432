import Head from 'next/head'
import { Main } from '../src/components/page/Main'
import { Title } from '../src/components/page/Title'
import { useAuth, useData, useOrg, useUpload } from '../src/contexts'
import { SimpleData } from '../src/components/stats'
import { Capitalize } from '../src/utils'
import { useEffect, useState } from 'react'
import { FileUpload, Integration } from '../src/types/orgTypes'
import { HiMail } from 'react-icons/hi'
import { BsTelephoneFill } from 'react-icons/bs'
import Jate from 'jates'
import MinimalLoading from '../src/components/helpers/MinimalLoading'
import { Button } from '../src/components/form'
import { useRouter } from 'next/router'
import CrunchAnalyticsSVG from '../src/utils/svg/crunchAnalytics'
import AddIntegrationSVG from '../src/utils/svg/addIntegration'
import NoDataSVG from '../src/utils/svg/noData'
import { useUploads } from '../src/data/useUploads'

export default function Dashboard() {
  const { user } = useAuth()
  const router = useRouter()
  const {
    newestIntegrations: [integrations, setIntegrations, integrationsLoading, setIntegrationsLoading],
  } = useData()
  const { orgFetch } = useOrg()

  const { data: uploads, isLoading: uploadsLoading } = useUploads()

  useEffect(() => {
    ;(async () => {
      try {
        const res = await orgFetch<Integration[]>(`/api/integrations`)
        const data = await res.json()
        if (res.ok) {
          setIntegrations(data)
          setIntegrationsLoading(false)
        }
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  return (
    <>
      <Head>
        <title>Dashboard</title>
      </Head>
      <Main>
        <Title>Welcome back {Capitalize(String(user?.nickname))}!</Title>
        <div className="dashboard-grid">
          <SimpleData style={{ width: '100%' }} event="/settings/organization/integrations" title="Integrations">
            {integrationsLoading ? (
              <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <MinimalLoading />
              </div>
            ) : (integrations?.length || 0) < 1 ? (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '2rem 0 1.5rem 0',
                  flexDirection: 'column',
                  gap: '15px',
                }}
              >
                {/* <img style={{ width: '30%' }} src="/static/images/add-integration.svg" /> */}
                <AddIntegrationSVG style={{ width: '60%' }} />
                <Button onClick={() => router.push('/settings/organization/integrations')} text="Add Integration" />
              </div>
            ) : (
              <div className="contact-info">
                {integrations
                  ?.filter((_, i) => i < 5)
                  .map(integration => {
                    return (
                      <div className="dashboard-integration" key={integration.id}>
                        {integration.name}
                      </div>
                    )
                  })}
              </div>
            )}
          </SimpleData>
          <SimpleData style={{ width: '100%' }} event="/analytics" title="Analytics">
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '15px',
                justifyContent: 'space-between',
                padding: '2rem 0 1.5rem 0',
              }}
            >
              {/* <img style={{ width: '30%' }} src="/static/images/crunch-analytics.svg" /> */}
              <CrunchAnalyticsSVG style={{ width: '30%' }} />
              <Button onClick={() => router.push('/analytics')} text="Crunch Analytics" />
            </div>
          </SimpleData>
          <SimpleData style={{ width: '100%' }} event="/settings/organization/upload" title="Uploads">
            {uploadsLoading ? (
              <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <MinimalLoading />
              </div>
            ) : (uploads?.length || 0) < 1 ? (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '15px',
                  justifyContent: 'space-between',
                  padding: '2rem 0 1.5rem 0',
                }}
              >
                {/* <img style={{ width: '30%' }} src="/static/images/no-data.svg" /> */}
                <NoDataSVG style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
                <Button onClick={() => router.push('/settings/organization/upload')} text="Start Upload" />
              </div>
            ) : (
              <div className="contact-info">
                {uploads
                  ?.sort((a, b) => {
                    return b.createdTime - a.createdTime
                  })
                  .filter((_, i) => i < 5)
                  .map(upload => {
                    return <UploadCard data={upload} key={upload.id} />
                  })}
              </div>
            )}
          </SimpleData>
          <SimpleData style={{ width: '100%' }} event={() => window.open('https://tekst.ai', '_blank')} title="Info">
            <div className="contact-info">
              <div className="contact-row">
                <HiMail />
                <a href="mailto:support@tekst.ai">support@tekst.ai</a>
              </div>
              <div className="contact-row">
                <BsTelephoneFill fontSize={13} />
                <a href="tel:+32469185727">+32 469 185 727</a>
              </div>
            </div>
          </SimpleData>
        </div>
      </Main>
    </>
  )
}

function UploadCard({ data }: { data: FileUpload }) {
  const { percentage } = useUpload()

  const [editUpload, setEditUpload] = useState(false)
  const [status, setStatus] = useState('')

  useEffect(() => {
    const p = percentage.find(p => p.id == data.id)?.percentage || 0
    if (data.status == 'FINISHED') {
      setStatus('completed')
    } else {
      setStatus(`${p}%`)
    }
  }, [JSON.stringify(percentage)])

  return (
    <div className="upload-card home" style={{ padding: '0.6rem .7rem' }}>
      <div className="info">
        <span style={{ display: 'flex', gap: '15px' }}>
          <h3>{data.name}</h3>
          <p>{new Jate(data.createdTime).format('dd/MM/yy')}</p>
        </span>
        <p>{data.totalChunks == 1 ? '< 5MB' : data.totalChunks * 5 - 1 + 'MB'}</p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'flex-end' }}>{status}</div>
    </div>
  )
}
